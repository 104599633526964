<template>
  <div class="productlist">
    <h2>Produkte</h2>

    <div class="no-products" v-if="products.length <= 0">
      Noch keine Produkte hinzugefügt
    </div>
    <div v-else>
      <table cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <th colspan="2">Produkt</th>
            <th>Anzahl</th>
            <th>Preis</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in products" :key="entry.product.id">
            <td class="image"><img :src="host + entry.product.image" /></td>
            <td class="name">
              <strong>{{ entry.product.name}} </strong><br />
              {{ entry.product.variant }}<br />
              <br />
              <a href="#" @click="$emit('remove', entry.product.id)"><small>Löschen</small></a>
            </td>
            <td class="amount">
              <InputNumber :min="1" v-model="entry.amount" showButtons buttonLayout="horizontal" />
              <br />
              <small>{{ entry.product.stock }} auf Lager</small>

              <small class="warning" v-if="entry.amount > entry.product.stock"><br />Warenbestand zu niedrig!</small>
            </td>
            <td class="price">
              {{ formatCurrency(entry.product.price) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { CartEntry } from '@/types'
import { formatCurrency } from '@/utils/currency'

export default defineComponent({
  name: 'ProductList',
  props: {
    products: {
      type: Object as PropType<CartEntry[]>,
      required: true
    }
  },
  emits: ['remove'],
  setup () {
    const host = process.env.VUE_APP_HOST

    return {
      formatCurrency,
      host
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.no-products {
  text-align: center;
  font-style: italic;
  font-size: 0.9em;
}

.productlist {
  margin: 20px 50px;
}

table {
  width: 100%;

  * {
    padding: 0;
    margin: 0;
  }

  td, th {
    text-align: left;
  }
  thead {
    tr {
      th {
        border-bottom: 1px solid black;
      }
    }
  }
  tbody {
    tr {
      td {
        border-bottom: 1px solid #eee;
      }
    }
  }

  .amount {
    padding-right: 20px;
    .p-inputtext {
      width: 50px;
      padding: 5px;
    }
  }

  .image {
    width: 250px;
  }

  .price {
    width: 100px;
  }

  .amount {
    width: 150px;
  }

  img {
    max-width: 200px;
    padding: 10px 20px;
  }

  .warning {
    color: red;
  }
}
</style>
